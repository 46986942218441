import { Container, Row, Col } from 'react-bootstrap'
import Link from 'next/link'
import Image from 'next/image'
import handleViewport from 'react-in-viewport'
// import{ Image } from 'react-bootstrap'

function WorkSection(props) {
	const { forwardedRef, inViewport } = props
	const projects = props?.projects || props.block
	const baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL

	const getStyles = (width) => {
		switch (width) {
			case 'col-4':
				return {
					divClass: 'project-small',
					colLg: '12',
					rowClass: 'project-user-unset',
				}
			case 'col-12':
				return {
					divClass: 'project-large',
					colLg: '6',
					rowClass: '',
				}
			default:
				return {
					divClass: '',
					colLg: '6',
					rowClass: '',
				}
		}
	}

	return (
		<section ref={forwardedRef} style={{minHeight: '1500px'}} className="work-section">
			{inViewport && (
				<div className="work-inner">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="heading">
									<h3>{projects?.short_title}</h3>
									<h2>{projects?.title}</h2>
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg={12}>
								<div className="our-project">
									<Row>
										{projects?.Projects.map((project, index) => {
											const projectStyles = getStyles(project.lg_col_width)
											const logoImgUrl = project.Logo?.data?.attributes?.url?.replace('uploads', 'uploads/f_webp')
											const projectImgUrl = project?.Snapshots?.data?.attributes?.url?.replace('uploads', 'uploads/f_webp')
											return (
												<Col
													key={index}
													lg={project.lg_col_width.replace('col-', '')}
													style={{minHeight : (project.lg_col_width.replace('col-', '') == '12') ? 488 : 682}}
												>
													<div
														className={`project ${projectStyles.divClass}`}
														style={{ backgroundColor: project.bg_color }}
													>
														{project?.Logo?.data?.attributes ?
															<Row>
																<Col lg={12}>
																	<div className="project-logo">
																		<Image
																			src={`${baseUrl}${logoImgUrl}`}
																			alt={project.Logo?.data?.attributes?.alternativeText ? project.Logo?.data?.attributes?.alternativeText : project.Logo?.data?.attributes?.name}
																			width={120}
																			height={35}
																		/>
																	</div>
																</Col>
															</Row>
															: null}

														<Row>
															<Col lg={projectStyles.colLg}>
																<Row>
																	<Col lg={12}>
																		<div className="project-content">
																			<h3>{project?.Description}</h3>
																		</div>
																	</Col>
																</Row>
																<Row
																	className={`${projectStyles.rowClass}`}
																>
																	<Col className="col-auto">
																		<div className={'project-user'}>
																			<div className="retention">
																				{project.user_retention && (
																					<>
																						<p>User Retention</p>
																						<span>
																							{project.user_retention}
																						</span>
																					</>
																				)}
																			</div>
																		</div>
																	</Col>

																	<Col xs={6}>
																		<div className="project-user">
																			<div className="retention">
																				{project.localized_domain && (
																					<>
																						<p>Localized Domains</p>
																						<span>
																							{project.localized_domain}
																						</span>
																					</>
																				)}
																			</div>
																		</div>
																	</Col>
																</Row>
																{project.link && (
																	<Row
																		className={`${projectStyles.rowClass}`}
																	>
																		<Col lg={12}>
																			<div className="project-link">
																				<Link href={project.link.href}>
																					View Case Study
																				</Link>
																			</div>
																		</Col>
																	</Row>
																)}
															</Col>

															<Col lg={projectStyles.colLg} className=''>
																{/* ${ (index == 0 || index == 4) ? 'd-flex' : '' }`} */}
																<div className={`project-img`}  >
																	<Image
																		width={ (index == 0 || index == 4) ? 550 : 0 }
																		height={ (index == 0 || index == 4) ? 353 : 0 }
																		sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
																		style={{
																			width: '100%',
																			height: 'auto',
																		}}
																		placeholder = 'empty' // "empty" | "blur"
																		// loading = 'eager' // {lazy} | {eager}
																		// priority
																		layout="responsive"
																		src={`${baseUrl}${projectImgUrl}`}
																		className={`electo-project-img project-${index}`}
																		alt={project?.Snapshots?.data?.attributes?.alternativeText ? project?.Snapshots?.data?.attributes?.alternativeText : project?.Snapshots?.data?.attributes?.name}
																	/>
																</div>
															</Col>
														</Row>
													</div>
												</Col>
											)
										})}
									</Row>
								</div>
							</Col>
						</Row>

						<Row>
							<Col lg={12}>
								<div className="View-all">
									<Link href="/case-studies" className="btn btn-secondary" aria-label='Case Studies'>
										View All Projects
									</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</section>
	)
}

const WorkSectionViewportBlock = handleViewport(WorkSection);

export default WorkSectionViewportBlock
